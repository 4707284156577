import Button from '@atoms/Button';
import Dialog from '@atoms/Dialog';
import Icon from '@atoms/Icon';
import Pill from '@atoms/Pill';
import twMerge from '@lib/twMerge';
import NoMatches from '@organisms/NoMatches';
import Pagination from '@organisms/Pagination';
import { type HTMLAttributes, Fragment } from 'react';
import { twJoin } from 'tailwind-merge';
import { RESULTS_PER_PAGE } from '../../../../constants';
import DataCard, { type DataCardProps } from './DataCard';
import LoadingCard from './LoadingCard';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.data
 * @param props.cardProps
 * @param props.dialog
 * @param props.examples
 * @param props.loading
 * @param props.totalCount
 */
function Cards({ className, data, totalCount, loading, dialog, ...rest }: CardsProps) {
  const dialogRefs: Record<string, HTMLDialogElement | null> = {};

  const handleCardClick = (label: string) => {
    if (dialog) {
      if (!dialogRefs[label]) {
        return;
      }
      // disableBodyScroll(dialogRefs[label]);
      dialogRefs[label].showModal();
    }
  };

  return (
    <div className={twJoin('w-full', !data.length && !loading && 'flex')}>
      <div className={twMerge('flex w-full flex-wrap gap-5', className)} {...rest}>
        {loading &&
          [1, 2, 3, 4, 5, 6].map((index) => (
            <LoadingCard
              key={index}
              className="md:shrink-0 md:grow-0 md:basis-[calc((100%-(1.25rem*2))/3)]"
            />
          ))}
        {!loading &&
          (data.length ?
            <>
              {data.map(
                ({
                  id,
                  label,
                  image,
                  imageAlt,
                  pills,
                  examples,
                  previewUrl,
                  className: cardClassName,
                  ...cardProps
                }) => (
                  <Fragment key={id}>
                    <DataCard
                      label={label}
                      pills={pills}
                      image={image}
                      imageAlt={imageAlt}
                      onClick={() => handleCardClick(label)}
                      className={twMerge(
                        'md:shrink-0 md:grow-0 md:basis-[calc((100%-(1.25rem*2))/3)]',
                        cardClassName,
                      )}
                      {...cardProps}
                    />
                    {dialog && (
                      <Dialog
                        ref={(ref) => {
                          dialogRefs[label] = ref;
                        }}
                      >
                        <div className={twJoin('flex flex-col gap-5', 'md:flex-row md:gap-16')}>
                          <img
                            src={image}
                            alt={imageAlt}
                            className={twJoin(
                              'max-h-64 rounded-card object-cover object-top',
                              'md:order-2 md:max-h-[400px] md:max-w-[420px]',
                              'lg:max-h-[840px] lg:w-[940px]',
                            )}
                            loading="lazy"
                          />
                          <div
                            className={twJoin(
                              'flex flex-col justify-center gap-5',
                              'md:max-w-xs md:gap-10 md:px-4',
                            )}
                          >
                            <div className="flex flex-col gap-2.5">
                              <p className={twJoin('text-h4 text-gray-600', 'md:text-h3')}>
                                {label}
                              </p>
                              {pills?.length && (
                                <div className="flex flex-wrap gap-1">
                                  {pills.map((pill) => (
                                    <Pill key={`${pill.id}${pill.label}`} className="bg-white">
                                      {pill.label}
                                    </Pill>
                                  ))}
                                </div>
                              )}
                            </div>
                            {examples?.length && (
                              <div className={twJoin('flex flex-col gap-1', 'md:order-1')}>
                                {examples.map(({ industry, url }) => (
                                  <a
                                    key={industry}
                                    href={url}
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                    className={twJoin(
                                      'flex h-11 w-full items-center justify-between gap-2 rounded-full bg-white px-5 text-base text-gray-600 transition-colors',
                                      'hover:text-primary',
                                    )}
                                  >
                                    <p>{industry} Example</p>
                                    <Icon name="link" />
                                  </a>
                                ))}
                              </div>
                            )}
                            {previewUrl && (
                              <Button className="order-3" asAnchor external to={previewUrl}>
                                Preview Theme
                              </Button>
                            )}
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </Fragment>
                ),
              )}
            </>
          : <NoMatches className="mx-auto" />)}
      </div>
      <div className="mx-auto mt-12">
        {totalCount > RESULTS_PER_PAGE && (
          <Pagination totalCount={totalCount} className="justify-center" />
        )}
      </div>
    </div>
  );
}

type CardsProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  data: (DataCardProps & {
    examples?: { id: string; industry: string; url: string }[] | null;
    previewUrl?: string;
  })[];
  loading: boolean;
  totalCount: number;
  dialog?: boolean;
};

export default Cards;
export type { CardsProps };
