import { Buffer } from 'buffer';
import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { TemplatesQuery, QueryTemplatesArgs } from '@gqlTypes';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RESULTS_PER_PAGE } from '../../../../constants';
import useGetFiltersFromParams from '../../../../hooks/useGetFiltersFromParams';
import formatTemplates from './templates.formatter';

const TEMPLATES_QUERY = gql(`
  query Templates(
    $tags: [String],
    $templateNames: [String],
    $first: Int, 
    $after: String, 
    $last: Int,
    $before: String
    ) {
    templates(
      tags: $tags,
      templateNames: $templateNames,
      first: $first,
      after: $after,
      last: $last,
      before: $before
      ) {
      totalCount
      edges {
        node {
          id
          name
          thumbnail
          previewUrl
          tags
          examples {
            edges {
              node {
                id
                industry
                url
              }
            }
          }
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useTemplatesData() {
  const [searchParams] = useSearchParams();
  const { themes, tags } = useGetFiltersFromParams<{
    tags: string[];
    themes: string[];
  }>(['tags', 'themes']);

  const pageParam = searchParams.get('page');
  const page = pageParam ? parseInt(pageParam, 10) : 1;
  const after = Buffer.from(String((page - 1) * RESULTS_PER_PAGE - 1)).toString('base64');

  const { data, ...result } = useQuery<TemplatesQuery, QueryTemplatesArgs>(TEMPLATES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      tags: tags || [],
      templateNames: themes || [],
      first: RESULTS_PER_PAGE,
      after,
    },
  });

  const formattedTemplatesData = useMemo(() => {
    if (!data?.templates.edges.length) {
      return [];
    }
    return formatTemplates(data);
  }, [data]);

  return [formattedTemplatesData, result, data] as const;
}

export default useTemplatesData;
