import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import config from '@config';
import logger from '@utils/logger';

const batchLink = new BatchHttpLink({
  uri: config.api,
  batchMax: 50,
});

const errors = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const exception = error.extensions?.['exception'];

      if (exception) {
        logger.warn(exception);
      }
    });
  }
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errors, batchLink]),
});

export default apolloClient;
