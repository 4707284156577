import twMerge from '@lib/twMerge';
import type { InputHTMLAttributes, LabelHTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.label
 * @param props.id
 * @param props.inputProps
 * @param props.handleSelect
 * @param props.value
 * @param props.name
 */
function Checkbox({
  className,
  handleSelect,
  label,
  value,
  id,
  name,
  inputProps,
  ...rest
}: CheckboxProps) {
  const { className: inputClassName, ...restInputProps } = inputProps || {};

  return (
    <label
      htmlFor={id}
      className={twMerge('flex cursor-pointer items-center gap-2.5', className)}
      {...rest}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={value}
        onChange={() => handleSelect(value, name)}
        className={twMerge(
          'h-4 w-4 cursor-pointer rounded-sm border-2 border-gray-500 text-green focus:ring-0 focus:ring-offset-0',
          inputClassName,
        )}
        {...restInputProps}
      />
      {label}
    </label>
  );
}

type CheckboxProps = LabelHTMLAttributes<HTMLLabelElement> & {
  handleSelect: (value: boolean, name: string) => void;
  label: string;
  name: string;
  value: boolean;
  inputProps?: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'checked' | 'onChange' | 'name'
  >;
};

export default Checkbox;
export type { CheckboxProps };
