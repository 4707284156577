import { useSearchParams } from 'react-router-dom';

/**
 * TODO: Write JSDocs.
 *
 * @param value
 */
function convertValue(value: string): string | number | boolean {
  if (value.toLowerCase() === 'true') {
    return true;
  }
  if (value.toLowerCase() === 'false') {
    return false;
  }
  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }
  return value.trim();
}

/**
 * TODO: Write JSDocs.
 *
 * @param filters
 */
function useGetFiltersFromParams<T extends Record<string, any>>(filters: (keyof T)[]) {
  const [searchParams] = useSearchParams();

  return filters.reduce((agg, filter) => {
    const newAgg = agg;
    const paramValue = searchParams.get(filter as string);
    if (paramValue) {
      const filterValue = paramValue.split(',').map((value) => convertValue(value)) as T[keyof T];
      newAgg[filter] = filterValue;
    }
    return newAgg;
  }, {} as Partial<T>);
}

export default useGetFiltersFromParams;
