import type { HTMLAttributes } from 'react';
import { useHref } from 'react-router-dom';

/**
 * Renders an icon with the specified name, height, and width.
 *
 * @extends HTMLAttributes<SVGElement>
 * @param props - The component props.
 * @param props.name - The name of the icon to render.
 * @param props.height - Optional. The height of the icon. Defaults to '1em'.
 * @param props.width - Optional. The width of the icon. Defaults to '1em'.
 * @returns An SVG element representing the icon.
 *
 * @example
 * // Basic usage
 * <Icon name="heart" />
 *
 * @example
 * // Advanced usage with custom height and width
 * <Icon name="star" height="2em" width="2em" />
 */
function Icon({ name, height = '1em', width = '1em', ...svgProps }: IconProps) {
  const base = useHref('/');
  const build = process.env.BUILD_VERSION ? `/${process.env.BUILD_VERSION}` : '';
  return (
    <svg height={height} width={width} {...svgProps}>
      <use href={`${base === '/' ? '' : base}${build}/icon-sprite.svg#${name}`} />
    </svg>
  );
}

type IconProps = HTMLAttributes<SVGElement> & {
  name: string;
  height?: string;
  width?: string;
};

export default Icon;
export type { IconProps };
