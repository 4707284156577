import Icon from '@atoms/Icon';
import twMerge from '@lib/twMerge';
import { useCallback, useMemo, type HTMLAttributes } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RESULTS_PER_PAGE } from '../../../constants';
import PaginationButton from './PaginationButton';

const MAX_PAGES_TO_DISPLAY = 5;

/**
 * TODO: Write JSDocs.
 *
 * @param currentPage
 * @param totalPages
 */
function getPageNumbersToDisplay(currentPage: number, totalPages: number) {
  const pageRange = (MAX_PAGES_TO_DISPLAY - 1) / 2;
  const firstPageToDisplay = Math.max(
    1,
    Math.min(totalPages - MAX_PAGES_TO_DISPLAY + 1, currentPage - pageRange),
  );
  const lastPageToDisplay = Math.min(
    totalPages,
    Math.max(MAX_PAGES_TO_DISPLAY, currentPage + pageRange),
  );

  const pagesNumbers: { isCurrentPage: boolean; pageNumber: number }[] = [];
  for (let i = firstPageToDisplay; i <= lastPageToDisplay; i += 1) {
    pagesNumbers.push({
      pageNumber: i,
      isCurrentPage: i === currentPage,
    });
  }

  return pagesNumbers;
}

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.totalCount
 */
function Pagination({ className, totalCount, ...divProps }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get('page');
  const currentPage = pageParam ? parseInt(pageParam, 10) : 1;
  const totalPages = Math.ceil(totalCount / RESULTS_PER_PAGE);

  const pageNumbers = useMemo(
    () => getPageNumbersToDisplay(currentPage, totalPages),
    [currentPage, totalPages],
  );

  const handlePageClick = useCallback(
    (goToPage: number) => {
      if (goToPage === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', `${goToPage}`);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const showLastPageEllipses = totalPages - currentPage > MAX_PAGES_TO_DISPLAY && !isLastPage;

  return (
    <div className={twMerge('flex gap-8', className)} {...divProps}>
      <PaginationButton
        disabled={isFirstPage}
        onClick={() => handlePageClick(currentPage - 1)}
        className="px-2.5"
      >
        <Icon name="chevron-left" className="mr-1" />
        Prev
      </PaginationButton>
      <div className="flex">
        {pageNumbers.map(({ pageNumber, isCurrentPage }) => (
          <PaginationButton
            key={pageNumber}
            className="w-8"
            disabled={isCurrentPage}
            onClick={() => handlePageClick(pageNumber)}
            isCurrentPage={isCurrentPage}
          >
            {pageNumber}
          </PaginationButton>
        ))}
        {showLastPageEllipses && (
          <>
            <div className="flex items-center justify-center">
              <p className="text-base">...</p>
            </div>
            <PaginationButton>{totalPages}</PaginationButton>
          </>
        )}
      </div>
      <PaginationButton
        disabled={isLastPage}
        onClick={() => handlePageClick(currentPage + 1)}
        className="px-2.5"
      >
        Next
        <Icon name="chevron-right" className="ml-1" />
      </PaginationButton>
    </div>
  );
}

type PaginationProps = HTMLAttributes<HTMLDivElement> & {
  totalCount: number;
};

export default Pagination;
export type { PaginationProps };
