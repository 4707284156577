import Accordion, { AccordionTitle, AccordionContent } from '@atoms/Accordion';
import Button from '@atoms/Button';
import type { CheckboxListItem, CheckboxListRef } from '@molecules/CheckboxList';
import CheckboxList from '@molecules/CheckboxList';
import CheckboxLoadingList from '@molecules/CheckboxLoadingList';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import useMedia from '../../../../hooks/useMedia';
import useShowcaseColorsContext from '../../contexts/ShowcaseColorsContext';
import useShowcaseFeaturesData from '../../data/showcaseFeatures';
import useShowcaseIndustriesData from '../../data/showcaseIndustries';
import useShowcaseTemplatesData from '../../data/showcaseTemplates';
import type { ShowcaseFiltersState } from '../../Showcases.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 * @param id
 */
function createChecklistItem(data: { [key: string]: any; name: string }[], id: string) {
  const checklistData: CheckboxListItem[] = data.map(({ name, ...rest }) => ({
    ...rest,
    id: `${id}Filter${name}`,
    name,
    label: name,
  }));
  return checklistData;
}

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.setFilters
 * @param props.filters
 */
function ShowcaseFilters({ filters, setFilters }: ShowcaseFiltersProps) {
  const media = useMedia();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showcaseColorsData, { loading: loadingShowcaseColors }] = useShowcaseColorsContext();
  const [showcaseFeaturesData, { loading: loadingShowcaseFeatures }] = useShowcaseFeaturesData();
  const [showcaseIndustriesData, { loading: loadingShowcaseIndustries }] =
    useShowcaseIndustriesData();
  const [showcaseTemplatesData, { loading: loadingShowcaseTemplates }] = useShowcaseTemplatesData();

  const industriesCheckboxListItems = useMemo(
    () => createChecklistItem(showcaseIndustriesData, 'industries'),
    [showcaseIndustriesData],
  );
  const industriesCheckboxRef = useRef<CheckboxListRef>(null);

  const colorsCheckboxListItems = useMemo(
    () => createChecklistItem(showcaseColorsData, 'colors'),
    [showcaseColorsData],
  );
  const colorsCheckboxRef = useRef<CheckboxListRef>(null);

  const templatesCheckboxListItems = useMemo(
    () => createChecklistItem(showcaseTemplatesData, 'templates'),
    [showcaseTemplatesData],
  );
  const templatesCheckboxRef = useRef<CheckboxListRef>(null);

  const featuresCheckboxListItems = useMemo(
    () => createChecklistItem(showcaseFeaturesData, 'features'),
    [showcaseFeaturesData],
  );
  const featuresCheckboxRef = useRef<CheckboxListRef>(null);

  const handleFilterSelect = (
    filter: keyof ShowcaseFiltersState,
    checked: boolean,
    name: string,
    options?: {
      radio: boolean;
    },
  ) => {
    setFilters((prev) => {
      const newFilter = {
        [filter]:
          checked ?
            [...(options?.radio ? [] : prev[filter]), name]
          : prev[filter].filter((p) => p !== name),
      };
      if (newFilter[filter]?.length) {
        searchParams.set(filter, newFilter[filter].join(','));
      } else {
        searchParams.delete(filter);
      }
      searchParams.delete('page');
      setSearchParams(searchParams, { preventScrollReset: true });
      return { ...prev, ...newFilter };
    });
  };

  const handleClear = () => {
    industriesCheckboxRef.current?.setSelected([]);
    templatesCheckboxRef.current?.setSelected([]);
    featuresCheckboxRef.current?.setSelected([]);
    colorsCheckboxRef.current?.setSelected([]);
    setFilters({ colors: [], features: [], industries: [], templates: [] });
    ['colors', 'features', 'industries', 'templates', 'page'].forEach((param) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  return (
    <>
      <Accordion initiallyOpened={media === 'desktop'} className="shadow-md">
        <AccordionTitle>Industry</AccordionTitle>
        <AccordionContent
          className="flex flex-col gap-1"
          initialHeight={media !== 'mobile' ? 446 : 0}
        >
          {!loadingShowcaseIndustries ?
            <CheckboxList
              items={industriesCheckboxListItems}
              onSelect={(checked, name) => handleFilterSelect('industries', checked, name)}
              ref={industriesCheckboxRef}
              initialChecked={filters.industries}
            />
          : <CheckboxLoadingList count={15} />}
        </AccordionContent>
      </Accordion>

      <Accordion initiallyOpened={media === 'desktop'} className="shadow-md">
        <AccordionTitle>Colour</AccordionTitle>
        <AccordionContent
          className="flex flex-col gap-1"
          initialHeight={media !== 'mobile' ? 106 : 0}
        >
          <div className="flex flex-wrap gap-3">
            {!loadingShowcaseColors ?
              <CheckboxList
                items={colorsCheckboxListItems}
                onSelect={(checked, name) =>
                  handleFilterSelect('colors', checked, name, { radio: true })
                }
                initialChecked={filters.colors}
                ref={colorsCheckboxRef}
                renderCheckbox={({ id, name, hex, value, handleSelect, ...rest }) => (
                  <input
                    key={id}
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={value}
                    onChange={() => handleSelect(value, name, true)}
                    className={twJoin(
                      'h-8 w-8 cursor-pointer rounded-full border-none outline-none transition-[brightness]',
                      'hover:brightness-75',
                      'checked:outline-gray-600',
                    )}
                    style={{ background: hex }}
                    {...rest}
                  />
                )}
              />
            : Array.from({ length: 9 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="h-8 w-8 rounded-full bg-gray-100" />
              ))
            }
          </div>
        </AccordionContent>
      </Accordion>

      <Accordion initiallyOpened={!!filters.templates.length} className="shadow-md">
        <AccordionTitle>Theme</AccordionTitle>
        <AccordionContent className="flex flex-col gap-1">
          {!loadingShowcaseTemplates ?
            <CheckboxList
              items={templatesCheckboxListItems}
              onSelect={(checked, name) => handleFilterSelect('templates', checked, name)}
              ref={templatesCheckboxRef}
              initialChecked={filters.templates}
            />
          : <CheckboxLoadingList count={11} />}
        </AccordionContent>
      </Accordion>

      <Accordion initiallyOpened={!!filters.features.length} className="shadow-md">
        <AccordionTitle>Features</AccordionTitle>
        <AccordionContent className="flex flex-col gap-1">
          {!loadingShowcaseFeatures ?
            <CheckboxList
              items={featuresCheckboxListItems}
              onSelect={(checked, name) => handleFilterSelect('features', checked, name)}
              ref={featuresCheckboxRef}
              initialChecked={filters.features}
            />
          : <CheckboxLoadingList count={2} />}
        </AccordionContent>
      </Accordion>

      <Button onClick={handleClear} className={twJoin('w-full', 'sm:w-fit')}>
        Clear
      </Button>
    </>
  );
}

type ShowcaseFiltersProps = {
  filters: ShowcaseFiltersState;
  setFilters: Dispatch<SetStateAction<ShowcaseFiltersState>>;
};

export default ShowcaseFilters;
