import type { Config } from './config.types';

const envConfigs = import.meta.glob<Config>('./env/*', { import: 'default', eager: true });
const env = process.env.NODE_ENV || 'development';

/**
 * Retrieves the configuration for the current environment.
 *
 * This function loads the appropriate configuration file based on the `NODE_ENV` environment
 * variable. If `NODE_ENV` is not set, it defaults to loading the 'local' environment configuration.
 *
 * @returns The configuration object for the current environment.
 *
 * @example
 * ```ts
 * // Basic usage
 * import config from 'config';
 *
 * const { env } = config;
 * ```
 *
 * @throws Will throw an error if the configuration file for the current environment cannot be loaded.
 */
function getConfig(): Config {
  const module = `./env/${env}.ts`;
  try {
    return envConfigs[module] as Config;
  } catch (e) {
    throw new Error('Error loading config');
  }
}

const config = getConfig();

export default config;
