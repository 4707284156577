import type { TemplatesQuery } from '@gqlTypes';
import type { FormattedTemplate, FormattedTemplates } from './templates.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatTemplates(data: TemplatesQuery): FormattedTemplates {
  return data.templates.edges.reduce((agg, edge) => {
    const newAgg = agg;
    const { node } = edge || {};

    if (node) {
      const examples =
        node.examples?.edges.length ?
          node.examples.edges.reduce(
            (examplesAgg, exampleEdge) => {
              const newExamplesAgg = examplesAgg;
              if (exampleEdge?.node) {
                const { id, industry, url } = exampleEdge.node;
                newExamplesAgg.push({ id, industry, url });
              }
              return newExamplesAgg;
            },
            [] as Exclude<FormattedTemplate['examples'], null>,
          )
        : null;

      const pills = node.tags?.length ? node.tags.map((tag) => ({ label: tag, id: tag })) : null;

      const { id, name, previewUrl, thumbnail } = node;

      newAgg.push({
        id: `${id}`,
        label: name,
        previewUrl,
        image: thumbnail,
        pills,
        examples,
      });
    }

    return newAgg;
  }, [] as FormattedTemplate[]);
}

export default formatTemplates;
