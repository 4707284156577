/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query ShowcaseColors(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseColors(\n      minCount: $minCount,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          hex\n          count\n        }\n      }\n    }\n  }\n':
    types.ShowcaseColorsDocument,
  '\n  query ShowcaseFeatures(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseFeatures(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n':
    types.ShowcaseFeaturesDocument,
  '\n  query ShowcaseIndustries($minCount: Int) {\n    showcaseIndustries(minCount: $minCount) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n':
    types.ShowcaseIndustriesDocument,
  '\n  query ShowcaseTemplates(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseTemplates(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n':
    types.ShowcaseTemplatesDocument,
  '\n  query Showcases(\n    $colors: [String],\n    $industries: [String],\n    $templates: [String],\n    $features: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcases(\n      colors: $colors, \n      industries: $industries, \n      templates: $templates, \n      features: $features,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          label\n          template\n          color\n          industry\n          url\n          previewUrl\n          features\n          thumbnail\n        }\n      }\n    }\n  }\n':
    types.ShowcasesDocument,
  '\n  query TemplateTags(\n    $minCount: Int, \n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templateTags(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n':
    types.TemplateTagsDocument,
  '\n  query Templates(\n    $tags: [String],\n    $templateNames: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templates(\n      tags: $tags,\n      templateNames: $templateNames,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          thumbnail\n          previewUrl\n          tags\n          examples {\n            edges {\n              node {\n                id\n                industry\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.TemplatesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ShowcaseColors(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseColors(\n      minCount: $minCount,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          hex\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ShowcaseColors(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseColors(\n      minCount: $minCount,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          hex\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ShowcaseFeatures(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseFeatures(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ShowcaseFeatures(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseFeatures(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ShowcaseIndustries($minCount: Int) {\n    showcaseIndustries(minCount: $minCount) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ShowcaseIndustries($minCount: Int) {\n    showcaseIndustries(minCount: $minCount) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ShowcaseTemplates(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseTemplates(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ShowcaseTemplates(\n    $minCount: Int,\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcaseTemplates(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Showcases(\n    $colors: [String],\n    $industries: [String],\n    $templates: [String],\n    $features: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcases(\n      colors: $colors, \n      industries: $industries, \n      templates: $templates, \n      features: $features,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          label\n          template\n          color\n          industry\n          url\n          previewUrl\n          features\n          thumbnail\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Showcases(\n    $colors: [String],\n    $industries: [String],\n    $templates: [String],\n    $features: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    showcases(\n      colors: $colors, \n      industries: $industries, \n      templates: $templates, \n      features: $features,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          label\n          template\n          color\n          industry\n          url\n          previewUrl\n          features\n          thumbnail\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query TemplateTags(\n    $minCount: Int, \n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templateTags(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TemplateTags(\n    $minCount: Int, \n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templateTags(\n      minCount: $minCount, \n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      edges {\n        node {\n          id\n          name\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query Templates(\n    $tags: [String],\n    $templateNames: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templates(\n      tags: $tags,\n      templateNames: $templateNames,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          thumbnail\n          previewUrl\n          tags\n          examples {\n            edges {\n              node {\n                id\n                industry\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Templates(\n    $tags: [String],\n    $templateNames: [String],\n    $first: Int, \n    $after: String, \n    $last: Int,\n    $before: String\n    ) {\n    templates(\n      tags: $tags,\n      templateNames: $templateNames,\n      first: $first,\n      after: $after,\n      last: $last,\n      before: $before\n      ) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          thumbnail\n          previewUrl\n          tags\n          examples {\n            edges {\n              node {\n                id\n                industry\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
