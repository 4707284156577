import Icon from '@atoms/Icon';
import twMerge from '@lib/twMerge';
import { type MutableRefObject, forwardRef, type DialogHTMLAttributes, useRef } from 'react';
import { twJoin } from 'tailwind-merge';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.children
 * @param props.open
 */
const Dialog = forwardRef<HTMLDialogElement, DialogProps>(
  ({ children, className, onClose, ...dialogProps }, ref) => {
    const internalRef: MutableRefObject<HTMLDialogElement | null> = useRef(null);

    const combinedRef = (node: HTMLDialogElement) => {
      internalRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        (ref as MutableRefObject<HTMLDialogElement | null>).current = node;
      }
    };

    const handleClick = (event: React.MouseEvent<HTMLDialogElement>) => {
      if (!internalRef.current) {
        return;
      }
      const rect = internalRef.current.getBoundingClientRect();
      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
      if (!isInDialog) {
        internalRef.current.close();
        if (onClose) {
          onClose();
        }
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <dialog
        onClick={handleClick}
        className={twMerge(
          'w-full overflow-visible rounded-dialog bg-white/80 p-4 backdrop-blur-md backdrop:bg-black/50',
          'sm:max-w-[90vw] sm:p-8',
          'md:w-fit',
          className,
        )}
        {...dialogProps}
        ref={combinedRef}
      >
        <Icon
          name="x"
          className={twJoin(
            'absolute -right-3 -top-4 cursor-pointer text-lg text-white',
            'sm:-right-5 sm:-top-5 sm:text-h4',
          )}
        />
        <div className="max-h-[90vh] overflow-auto">{children}</div>
      </dialog>
    );
  },
);

type DialogProps = DialogHTMLAttributes<HTMLDialogElement> & {
  onClose?: () => void; //
};

export default Dialog;
export type { DialogProps };
