import type { ShowcaseColorsQuery } from '@gqlTypes';
import type { FormattedShowcaseColor, FormattedShowcaseColors } from './showcaseColors.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatShowcaseColors(data: ShowcaseColorsQuery): FormattedShowcaseColors {
  const formatted = data.showcaseColors.edges.reduce((agg, edge) => {
    const newAgg = agg;

    if (edge?.node) {
      const { id, hex, count, name } = edge.node;
      newAgg.push({ id, hex, count, name });
    }

    return newAgg;
  }, [] as FormattedShowcaseColor[]);

  return formatted.sort((a, b) => b.count - a.count);
}

export default formatShowcaseColors;
