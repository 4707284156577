import twMerge from '@lib/twMerge';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.children
 * @param props.className
 * @param props.href
 * @param props.isActive
 * @param props.to
 */
function Tab({ children, className, to, isActive, ...rest }: TabProps) {
  return (
    <Link
      className={twMerge(
        'flex items-center justify-center rounded-full px-8 py-3 text-h5 transition-colors',
        isActive ?
          'cursor-default bg-primary text-white'
        : 'bg-white text-text hover:bg-gray-100 hover:text-primary',
        className,
      )}
      aria-disabled={isActive}
      to={!isActive ? to : '/'}
      {...rest}
    >
      {children}
    </Link>
  );
}

type TabProps = LinkProps & {
  isActive: boolean;
};

export default Tab;
export type { TabProps };
