import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';
import { useEffect, useRef, useState } from 'react';
import useAccordionContent from '../contexts/AccordionContentContext';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.children
 * @param props.className
 * @param props.initialHeight
 */
function AccordionContent({
  children,
  className,
  initialHeight = 0,
  ...rest
}: AccordionContentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(initialHeight);
  const { open } = useAccordionContent();

  useEffect(() => {
    setHeight(open ? ref.current?.offsetHeight || 0 : 0);
  }, [children, open, ref.current?.offsetHeight]);

  return (
    <div className="overflow-y-hidden transition-[height]" style={{ height }} {...rest}>
      <div className={twMerge('p-5 pt-2.5', className)} ref={ref}>
        {children}
      </div>
    </div>
  );
}

type AccordionContentProps = HTMLAttributes<HTMLDivElement> & { initialHeight?: number };

export default AccordionContent;
export type { AccordionContentProps };
