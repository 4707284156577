import twMerge from '@lib/twMerge';
import { Children, useMemo, useState, type HTMLAttributes } from 'react';
import { AccordionContentContext } from './contexts/AccordionContentContext';
import { AccordionTitleContext } from './contexts/AccordionTitleContext';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.title
 * @param props.initiallyOpened
 * @param props.children
 */
function Accordion({ children, className, initiallyOpened = false, ...rest }: AccordionProps) {
  const [open, setOpen] = useState(initiallyOpened);

  const childrenArray = Children.toArray(children);
  const [accordionTitle, accordionContent] = childrenArray;

  const accordionTitleContextValue = useMemo(
    () => ({
      open,
      handleClick: () => setOpen((prev) => !prev),
    }),
    [open, setOpen],
  );

  const accordionContentContextValue = useMemo(
    () => ({
      open,
    }),
    [open],
  );

  return (
    <div className={twMerge('rounded-card border border-gray-200 bg-white', className)} {...rest}>
      <AccordionTitleContext.Provider value={accordionTitleContextValue}>
        {accordionTitle}
      </AccordionTitleContext.Provider>
      <AccordionContentContext.Provider value={accordionContentContextValue}>
        {accordionContent}
      </AccordionContentContext.Provider>
    </div>
  );
}

type AccordionProps = HTMLAttributes<HTMLDivElement> & {
  initiallyOpened?: boolean;
};

export default Accordion;
export type { AccordionProps };
