import Container from '@atoms/Container';
import twMerge from '@lib/twMerge';
import { type HTMLAttributes } from 'react';
import { useLocation } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

/**
 * TODO: Write JSDocs.
 */
function useGetPageForTitle() {
  const { pathname } = useLocation();
  if (pathname === '/portfolio') {
    return 'Website Showcases';
  }
  return 'Website Themes';
}

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 */
function PageTitle({ className, ...rest }: PageTitleProps) {
  const pageName = useGetPageForTitle();
  return (
    <div
      className={twMerge(
        'w-full bg-[conic-gradient(from_34.93deg_at_62.03%_-18.86%,#9CCAFF_0deg,#2962FF_29.35deg,#81A1FF_45.49deg,#7EBAFF_72.9deg,#285AFF_201.25deg,#5FA7FB_298.12deg,#9CCAFF_360deg)]',
        className,
      )}
      {...rest}
    >
      <Container
        className={twJoin(
          'flex w-full flex-col items-center justify-center pb-[4.5rem] pt-10',
          'sm:pb-24 sm:pt-16',
        )}
      >
        <h1 className={twJoin('text-center text-h2 text-white', 'sm:text-h1')}>{pageName}</h1>
        <p className={twJoin('mt-3 text-center text-lg text-white', 'sm:mt-1')}>
          Explore our customisable themes. Visit the Portfolio tab to view our client sites.
        </p>
      </Container>
    </div>
  );
}

type PageTitleProps = HTMLAttributes<HTMLDivElement>;

export default PageTitle;
export type { PageTitleProps };
