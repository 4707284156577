import type { Config } from '../config.types';
import defaultConfig from '../defaults';

const developmentConfig: Config = {
  ...defaultConfig,
  api: 'https://development.services.lsapis.com.au/graphql',
  // api: 'http://localhost:3005/graphql', // Use this if running ls-website-themes-service locally
  hot: true,
  loggerLevel: 'debug',
  sentry: {
    ...defaultConfig.sentry,
    replaysSessionSampleRate: 0.5,
    debug: false,
  },
  sentryReplayIntegration: {
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  },
};

export default developmentConfig;
