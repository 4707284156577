import Accordion, { AccordionTitle, AccordionContent } from '@atoms/Accordion';
import type { CheckboxListItem } from '@molecules/CheckboxList';
import CheckboxList from '@molecules/CheckboxList';
import CheckboxLoadingList from '@molecules/CheckboxLoadingList';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMedia from '../../../../hooks/useMedia';
import useTemplateTagsData from '../../data/templateTags';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.setTags
 * @param props.templateTagsData
 * @param props.tags
 */
function TemplateFilters({ tags, setTags }: TemplateFiltersProps) {
  const media = useMedia();
  const [templateTagsData, { loading }] = useTemplateTagsData();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTagsSelect = (value: boolean, name: string) => {
    setTags((prev) => {
      const newTags = value ? [...prev, name] : prev.filter((tag) => tag !== name);
      if (newTags.length) {
        searchParams.set('tags', newTags.join(','));
      } else {
        searchParams.delete('tags');
        searchParams.delete('page');
      }
      setSearchParams(searchParams, { preventScrollReset: true });
      return newTags;
    });
  };

  const [tagsCheckboxListItems] = useMemo(() => {
    const allTagNames: string[] = [];
    const tagsAsCheckboxListItems: CheckboxListItem[] = templateTagsData.map(({ name }) => {
      allTagNames.push(name);
      return {
        id: `tagsFilter${name}`,
        name,
        label: name,
      };
    });
    return [tagsAsCheckboxListItems, allTagNames];
  }, [templateTagsData]);

  return (
    <Accordion initiallyOpened={media === 'desktop'} className="shadow-md">
      <AccordionTitle>Pages</AccordionTitle>
      <AccordionContent className="flex flex-col gap-1">
        {!loading ?
          <CheckboxList
            items={tagsCheckboxListItems}
            onSelect={handleTagsSelect}
            initialChecked={tags}
          />
        : <CheckboxLoadingList count={2} />}
      </AccordionContent>
    </Accordion>
  );
}

type TemplateFiltersProps = {
  setTags: Dispatch<SetStateAction<string[]>>;
  tags: string[];
};

export default TemplateFilters;
