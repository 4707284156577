import type { ShowcasesQuery } from '@gqlTypes';
import type { FormattedShowcase, FormattedShowcases } from './showcases.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatShowcases(data: ShowcasesQuery): FormattedShowcases {
  return data.showcases.edges.reduce((agg, edge) => {
    const newAgg = agg;

    if (edge?.node) {
      const { id, label, url, thumbnail, industry, color, template, features } = edge.node;
      newAgg.push({
        id,
        label: label || url,
        image: thumbnail,
        url,
        pills: [
          { label: industry, id: 'industry' },
          { label: color, id: 'color' },
          { label: template, id: 'template' },
          ...(features?.length ?
            features.map((feature) => ({ label: feature, id: 'feature' }))
          : []),
        ],
      });
    }

    return newAgg;
  }, [] as FormattedShowcase[]);
}

export default formatShowcases;
