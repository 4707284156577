import type { ShowcaseIndustriesQuery } from '@gqlTypes';
import type {
  FormattedShowcaseIndustry,
  FormattedShowcaseIndustries,
} from './showcaseIndustries.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatShowcaseIndustries(data: ShowcaseIndustriesQuery): FormattedShowcaseIndustries {
  const formatted = data.showcaseIndustries.edges.reduce((agg, edge) => {
    const newAgg = agg;

    if (edge?.node) {
      const { id, count, name } = edge.node;
      newAgg.push({ id, count, name });
    }

    return newAgg;
  }, [] as FormattedShowcaseIndustry[]);

  return formatted.sort((a, b) => a.name.localeCompare(b.name));
}

export default formatShowcaseIndustries;
