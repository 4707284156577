/**
 * Generates a pseudo-random width based on the index.
 *
 * Ensures that the width appears to be random, however the same index will always produce the same pseudo-random width.
 *
 * @param index
 * @param minWidth
 * @param maxWidth
 */
function createPseudoRandomWidth(index: number, minWidth = 40, maxWidth = 90) {
  // Use a simple formula to generate a pseudo-random number based on the index
  const pseudoRandom = Math.sin(index) * 10000;
  // Normalize to a number between 0 and 1
  const normalized = pseudoRandom - Math.floor(pseudoRandom);
  // Scale to desired range (minWidth to 100)
  return `${minWidth + normalized * (maxWidth - minWidth)}%`;
}

export default createPseudoRandomWidth;
