import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';
import invalidIcon from './assets/invalid.svg';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 */
function NoMatches({ className, ...divProps }: NoMatchesProps) {
  return (
    <div
      className={twMerge('flex flex-col items-center justify-center gap-5', className)}
      {...divProps}
    >
      <img src={invalidIcon} alt="No matches found" className="h-[138px] w-[190px]" />
      <p className="text-h4 text-gray-600">No exact matches</p>
      <p className="text-base text-gray-500">Please try adjusting your search criteria</p>
    </div>
  );
}

type NoMatchesProps = HTMLAttributes<HTMLDivElement>;

export default NoMatches;
export type { NoMatchesProps };
