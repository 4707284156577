import type { TemplateTagsQuery } from '@gqlTypes';
import type { FormattedTemplateTag, FormattedTemplateTags } from './templateTags.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatTemplateTags(data: TemplateTagsQuery): FormattedTemplateTags {
  const formatted = data.templateTags.edges.reduce((agg, edge) => {
    const newAgg = agg;
    if (edge?.node) {
      const { id, count, name } = edge.node;
      newAgg.push({ id, count, name });
    }
    return newAgg;
  }, [] as FormattedTemplateTag[]);

  return formatted.sort((a, b) => a.name.localeCompare(b.name));
}

export default formatTemplateTags;
