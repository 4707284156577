import { Buffer } from 'buffer';
import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { ShowcasesQuery, QueryShowcasesArgs } from '@gqlTypes';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RESULTS_PER_PAGE } from '../../../../constants';
import useGetFiltersFromParams from '../../../../hooks/useGetFiltersFromParams';
import formatShowcases from './showcases.formatter';

const SHOWCASES_QUERY = gql(`
  query Showcases(
    $colors: [String],
    $industries: [String],
    $templates: [String],
    $features: [String],
    $first: Int, 
    $after: String, 
    $last: Int,
    $before: String
    ) {
    showcases(
      colors: $colors, 
      industries: $industries, 
      templates: $templates, 
      features: $features,
      first: $first,
      after: $after,
      last: $last,
      before: $before
      ) {
      totalCount
      edges {
        node {
          id
          label
          template
          color
          industry
          url
          previewUrl
          features
          thumbnail
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useShowcasesData() {
  const [searchParams] = useSearchParams();
  const filterValues = useGetFiltersFromParams<{
    colors: string[];
    features: string[];
    industries: string[];
    templates: string[];
  }>(['colors', 'features', 'templates', 'industries']);

  const pageParam = searchParams.get('page');
  const page = pageParam ? parseInt(pageParam, 10) : 1;
  const after = Buffer.from(String((page - 1) * RESULTS_PER_PAGE - 1)).toString('base64');

  const { data, ...result } = useQuery<ShowcasesQuery, QueryShowcasesArgs>(SHOWCASES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...filterValues,
      first: RESULTS_PER_PAGE,
      after,
    },
  });

  const formattedShowcasesData = useMemo(() => {
    if (!data?.showcases.edges.length) {
      return [];
    }
    return formatShowcases(data);
  }, [data]);

  return [formattedShowcasesData, result, data] as const;
}

export default useShowcasesData;
