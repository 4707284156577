import Checkbox from '@atoms/Checkbox';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';

/**
 * TODO: Write JSDocs.
 *
 */
const CheckboxList = forwardRef<CheckboxListRef, CheckboxListProps>(
  ({ items, initialChecked = [], onSelect, renderCheckbox }, ref) => {
    const [selected, setSelected] = useState<string[]>(initialChecked);

    useImperativeHandle(
      ref,
      () => ({
        setSelected,
      }),
      [setSelected],
    );

    const handleSelect = (value: boolean, name: string, radio?: boolean) => {
      if (radio) {
        setSelected(!value ? [name] : []);
      } else if (!value) {
        setSelected((prev) => [...prev, name]);
      } else {
        setSelected((prev) => prev.filter((item) => item !== name));
      }
      onSelect(!value, name);
    };

    return items.map(
      ({ id, label, name, ...rest }) =>
        renderCheckbox?.({
          id,
          label,
          name,
          value: selected.includes(name),
          handleSelect,
          ...rest,
        }) || (
          <Checkbox
            key={id}
            id={id}
            label={label}
            name={name}
            value={selected.includes(name)}
            handleSelect={handleSelect}
          />
        ),
    );
  },
);

type CheckboxListItem = {
  [key: string]: any;
  id: string;
  label: string;
  name: string;
};

type CheckboxListProps = {
  items: CheckboxListItem[];
  onSelect: (value: boolean, name: string) => void;
  initialChecked?: string[];
  renderCheckbox?: (
    params: CheckboxListItem & {
      handleSelect: (value: boolean, name: string, radio?: boolean) => void;
      value: boolean;
    },
  ) => ReactNode;
};

type CheckboxListRef = {
  setSelected: Dispatch<SetStateAction<string[]>>;
};

export default CheckboxList;
export type { CheckboxListProps, CheckboxListItem, CheckboxListRef };
