import Container from '@atoms/Container';
import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 */
function Footer({ className, ...divProps }: FooterProps) {
  return (
    <div className={twMerge('bg-white', className)} {...divProps}>
      <Container className="py-8">
        <p className={twJoin('text-center text-base text-gray-500', 'lg:text-left')}>
          Copyright © 1993 - 2024 &#183; Localsearch Operations Pty Ltd
        </p>
        <div className="flex-1" />
      </Container>
    </div>
  );
}

type FooterProps = HTMLAttributes<HTMLDivElement>;

export default Footer;
export type { FooterProps };
