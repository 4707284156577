import { Outlet } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import Footer from './sections/Footer';
import Header from './sections/Header';
import NavigationTabs from './sections/NavigationTabs';
import PageTitle from './sections/PageTitle';

/**
 * TODO: Write JSDocs.
 */
function LayoutTemplate() {
  return (
    <>
      <Header />
      <PageTitle />
      <NavigationTabs className={twJoin('mx-auto translate-y-[-50%]', 'sm:mb-16')} />
      <Outlet />
      <Footer className="mt-12" />
    </>
  );
}

export default LayoutTemplate;
