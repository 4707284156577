import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { ShowcaseTemplatesQuery, QueryShowcaseTemplatesArgs } from '@gqlTypes';
import { useMemo } from 'react';
import formatShowcaseTemplates from './showcaseTemplates.formatter';

const SHOWCASE_TEMPLATES_QUERY = gql(`
  query ShowcaseTemplates(
    $minCount: Int,
    $first: Int, 
    $after: String, 
    $last: Int,
    $before: String
    ) {
    showcaseTemplates(
      minCount: $minCount, 
      first: $first,
      after: $after,
      last: $last,
      before: $before
      ) {
      edges {
        node {
          id
          name
          count
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useShowcaseTemplatesData() {
  const { data, ...result } = useQuery<ShowcaseTemplatesQuery, QueryShowcaseTemplatesArgs>(
    SHOWCASE_TEMPLATES_QUERY,
    {
      variables: {
        first: 100,
        minCount: 1,
      },
    },
  );

  const formattedShowcaseTemplates = useMemo(() => {
    if (!data?.showcaseTemplates.edges.length) {
      return [];
    }
    return formatShowcaseTemplates(data);
  }, [data]);

  return [formattedShowcaseTemplates, result] as const;
}

export default useShowcaseTemplatesData;
