import Button from '@atoms/Button';
import Container, { type ContainerProps } from '@atoms/Container';
import Icon from '@atoms/Icon';
import twMerge from '@lib/twMerge';
import { twJoin } from 'tailwind-merge';
import localsearchLogo from './assets/localsearch-logo.svg';

/**
 * TODO: Write JSDocs.
 *
 * @extends ContainerProps
 * @param props - Component props.
 * @param props.className - Optional. Extend the styles of the root component.
 */
function Header({ className, ...containerProps }: HeaderProps) {
  return (
    <Container
      className={twMerge('flex h-20 items-center gap-6', 'sm:h-28', className)}
      {...containerProps}
    >
      <a href="https://www.localsearch.com.au" target="_blank" rel="noreferrer">
        <img
          src={localsearchLogo}
          alt="Localsearch logo"
          className={twJoin('h-[29.2px] w-[122.5px]', 'sm:h-[41.5px] sm:w-[174px]')}
        />
      </a>
      <div className="flex-1" />
      <a
        href="tel:1300360867"
        className={twJoin(
          'hidden',
          'h-11 items-center gap-1 rounded-full px-5 py-2.5 transition-colors sm:flex',
          'hover:bg-gray-200 hover:text-primary',
        )}
      >
        <Icon name="tablet" className="text-primary" />
        <p className="text-lg">1300 360 867</p>
      </a>
      <Button asAnchor external to="https://business.localsearch.com.au/contact-us/">
        Contact
      </Button>
    </Container>
  );
}

type HeaderProps = ContainerProps;

export default Header;
export type { HeaderProps };
