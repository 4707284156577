import type { Config } from '../config.types';
import defaultConfig from '../defaults';

const productionConfig: Config = {
  ...defaultConfig,
  api: 'https://services.lsapis.com.au/graphql',
  deploy: {
    ...defaultConfig.deploy,
    Aliases: 'websites.localsearch.com.au',
    CertificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/79f25c7d-b6aa-42c5-af7a-f13ef98d8d37',
    Domain: 'websites.localsearch.com.au',
    HostedZoneId: 'Z2FDTNDATAQYW2',
    WebAclArn:
      'arn:aws:wafv2:us-east-1:708891215413:global/webacl/ls-www/6cc11d3e-1fcb-46cd-a3ab-a404a24e84ab',
  },
  loggerLevel: 'error',
  sentry: {
    ...defaultConfig.sentry,
    replaysSessionSampleRate: 0.1,
    debug: false,
  },
  sentryReplayIntegration: {
    // Mask/block for privacy concerns in production
    maskAllText: true,
    maskAllInputs: true,
    blockAllMedia: true,
  },
};

export default productionConfig;
