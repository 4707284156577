import { ApolloProvider } from '@apollo/client';
import config from '@config';
import apolloClient from '@lib/apolloClient';
import SentryRouteErrorFallback from '@organisms/SentryRouteErrorBoundary';
import {
  ErrorBoundary as SentryErrorBoundary,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
  showReportDialog,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  ScrollRestoration,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Layout from './Layout';
import NotFound from './NotFound';
import ShowcasesPage from './Showcases';
import TemplatesPage from './Templates';

if (config.env !== 'development') {
  init({
    ...config.sentry,
    beforeSend(event) {
      if (event.exception && event.event_id) {
        showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(config.sentryReplayIntegration),
    ],
  });
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/*',
      element: (
        <SentryErrorBoundary fallback={<NotFound />} showDialog>
          <ApolloProvider client={apolloClient}>
            <Layout />
            <ScrollRestoration />
          </ApolloProvider>
        </SentryErrorBoundary>
      ),
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          path: 'portfolio',
          element: <ShowcasesPage />,
        },
        {
          path: '/*',
          element: <TemplatesPage />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ],
  {
    basename: `/${process.env.BASE_PATH || ''}`,
  },
);

export default router;
