import type { ShowcaseFeaturesQuery } from '@gqlTypes';
import type { FormattedShowcaseFeature, FormattedShowcaseFeatures } from './showcaseFeatures.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatShowcaseFeatures(data: ShowcaseFeaturesQuery): FormattedShowcaseFeatures {
  const formatted = data.showcaseFeatures.edges.reduce((agg, edge) => {
    const newAgg = agg;

    if (edge?.node) {
      const { id, count, name } = edge.node;
      newAgg.push({ id, count, name });
    }

    return newAgg;
  }, [] as FormattedShowcaseFeature[]);

  return formatted.sort((a, b) => a.name.localeCompare(b.name));
}

export default formatShowcaseFeatures;
