import type { Config } from '../config.types';
import defaultConfig from '../defaults';

const stagingConfig: Config = {
  ...defaultConfig,
  api: 'https://staging.services.lsapis.com.au/graphql',
  deploy: {
    ...defaultConfig.deploy,
    Aliases: 'websitethemes.staging.localsearch.cloud',
    CertificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/6a9d2781-b58e-4788-a34f-9c6630bf3662',
    Domain: 'websitethemes.staging.localsearch.cloud',
    WebAclArn:
      'arn:aws:wafv2:us-east-1:789417954668:global/webacl/ls-web-react-development/dd0deeb0-39f8-46ea-9a5b-47f12c4ee637',
  },
  loggerLevel: 'error',
  sentry: {
    ...defaultConfig.sentry,
    replaysSessionSampleRate: 1,
    debug: false,
  },
  sentryReplayIntegration: {
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  },
};

export default stagingConfig;
