import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { ShowcaseIndustriesQuery, QueryShowcaseIndustriesArgs } from '@gqlTypes';
import { useMemo } from 'react';
import formatShowcaseIndustries from './showcaseIndustries.formatter';

const SHOWCASE_INDUSTRIES_QUERY = gql(`
  query ShowcaseIndustries($minCount: Int) {
    showcaseIndustries(minCount: $minCount) {
      edges {
        node {
          id
          name
          count
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useShowcaseIndustriesData() {
  const { data, ...result } = useQuery<ShowcaseIndustriesQuery, QueryShowcaseIndustriesArgs>(
    SHOWCASE_INDUSTRIES_QUERY,
    {
      variables: {
        minCount: 1,
      },
    },
  );

  const formattedShowcaseIndustries = useMemo(() => {
    if (!data?.showcaseIndustries.edges.length) {
      return [];
    }
    return formatShowcaseIndustries(data);
  }, [data]);

  return [formattedShowcaseIndustries, result] as const;
}

export default useShowcaseIndustriesData;
