import { useState, useEffect } from 'react';

const SCREENS = {
  mobile: 0,
  tablet: 550,
  desktop: 1024,
} as const;

/**
 * Get the screen size from Tailwind's config based on the window width.
 *
 * @param width - The window's inner width.
 * @returns - The screen size (e.g., 'mobile', 'tablet', 'desktop').
 */
function getScreenSize(width: number): keyof typeof SCREENS {
  const screenSizes = Object.keys(SCREENS)
    .map((key) => ({
      name: key as keyof typeof SCREENS,
      size: SCREENS[key as keyof typeof SCREENS],
    }))
    .sort((a, b) => a.size - b.size);

  for (let i = screenSizes.length - 1; i >= 0; i -= 1) {
    const screenSize = screenSizes[i] as {
      name: keyof typeof SCREENS;
      size: number;
    };
    if (width >= screenSize.size) {
      return screenSize.name;
    }
  }

  return 'mobile';
}

/**
 * Get the current window dimensions.
 *
 * @returns - The window dimensions { width, height }.
 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * Hook to get the current screen size based on Tailwind's config.
 *
 * @returns - The screen size (e.g., 'sm', 'md', 'lg').
 */
function useMedia() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [screenSize, setScreenSize] = useState(getScreenSize(windowDimensions.width));

  useEffect(() => {
    const handleResize = () => {
      const newDimensions = getWindowDimensions();
      setWindowDimensions(newDimensions);
      setScreenSize(getScreenSize(newDimensions.width));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
}

export default useMedia;
