import type { PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';
import type { FormattedShowcaseColors } from '../../data/showcaseColors';
import useShowcaseColorsData from '../../data/showcaseColors';

const ShowcaseColorsContext = createContext<ShowcaseContextValues | [[], Record<string, never>]>([
  [],
  {},
]);

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.children
 */
function ShowcaseColorsContextProvider({ children }: PropsWithChildren) {
  const [data, result] = useShowcaseColorsData();
  const value = useMemo(() => [data, result] satisfies ShowcaseContextValues, [data, result]);

  return <ShowcaseColorsContext.Provider value={value}>{children}</ShowcaseColorsContext.Provider>;
}

/**
 * TODO: Write JSDocs.
 */

type ShowcaseContextValues = [
  colors: FormattedShowcaseColors,
  result: ReturnType<typeof useShowcaseColorsData>[1],
];

export default ShowcaseColorsContextProvider;
export { ShowcaseColorsContext };
export type { ShowcaseContextValues };
