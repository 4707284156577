import Container from '@atoms/Container';
import twMerge from '@lib/twMerge';
import { Children, type HTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.children
 */
function FilteredCards({ className, children, ...rest }: FilteredCardsProps) {
  const childrenArray = Children.toArray(children);
  const [filters, cards] = childrenArray;
  return (
    <Container className={twMerge('flex flex-col gap-10', 'lg:flex-row', className)} {...rest}>
      <div className="min-w-80 lg:w-80">{filters}</div>
      {cards}
    </Container>
  );
}

type FilteredCardsProps = HTMLAttributes<HTMLDivElement>;

export default FilteredCards;
export type { FilteredCardsProps };
