import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { TemplateTagsQuery, QueryTemplateTagsArgs } from '@gqlTypes';
import { useMemo } from 'react';
import formatTemplateTags from './templateTags.formatter';

const TEMPLATE_TAGS_QUERY = gql(`
  query TemplateTags(
    $minCount: Int, 
    $first: Int, 
    $after: String, 
    $last: Int,
    $before: String
    ) {
    templateTags(
      minCount: $minCount, 
      first: $first,
      after: $after,
      last: $last,
      before: $before
      ) {
      edges {
        node {
          id
          name
          count
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useTemplateTagsData() {
  const { data, ...result } = useQuery<TemplateTagsQuery, QueryTemplateTagsArgs>(
    TEMPLATE_TAGS_QUERY,
    {
      variables: {
        first: 100,
        minCount: 1,
      },
    },
  );

  const formattedTemplateTags = useMemo(() => {
    if (!data?.templateTags.edges.length) {
      return [];
    }
    return formatTemplateTags(data);
  }, [data]);

  return [formattedTemplateTags, result] as const;
}

export default useTemplateTagsData;
