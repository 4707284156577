import twMerge from '@lib/twMerge';
import type { HTMLAttributes, ReactNode } from 'react';
/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.children
 */
function Pill({ className, children, ...rest }: PillProps) {
  return (
    <div
      className={twMerge(
        'flex h-6 items-center justify-center rounded-full bg-gray-100 px-4 text-xs font-semibold text-gray-500',
        className,
      )}
      {...rest}
    >
      {typeof children === 'string' ?
        <p>{children}</p>
      : children}
    </div>
  );
}

type PillProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  children: string | ReactNode;
};

export default Pill;
export type { PillProps };
