import { useQuery } from '@apollo/client';
import { gql } from '@gql';
import type { ShowcaseColorsQuery, QueryShowcaseColorsArgs } from '@gqlTypes';
import { useMemo } from 'react';
import formatShowcaseColors from './showcaseColors.formatter';

const SHOWCASE_COLORS_QUERY = gql(`
  query ShowcaseColors(
    $minCount: Int,
    $first: Int, 
    $after: String, 
    $last: Int,
    $before: String
    ) {
    showcaseColors(
      minCount: $minCount,
      first: $first,
      after: $after,
      last: $last,
      before: $before
      ) {
      edges {
        node {
          id
          name
          hex
          count
        }
      }
    }
  }
`);

/**
 * TODO: Write JSDocs.
 *
 */
function useShowcaseColorsData() {
  const { data, ...result } = useQuery<ShowcaseColorsQuery, QueryShowcaseColorsArgs>(
    SHOWCASE_COLORS_QUERY,
    {
      variables: {
        first: 100,
        minCount: 1,
      },
    },
  );

  const formattedShowcaseColors = useMemo(() => {
    if (!data?.showcaseColors.edges.length) {
      return [];
    }
    return formatShowcaseColors(data);
  }, [data]);

  return [formattedShowcaseColors, result] as const;
}

export default useShowcaseColorsData;
