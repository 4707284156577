import { useContext } from 'react';
import type { ShowcaseContextValues } from './ShowcaseColorsContext';
import { ShowcaseColorsContext } from './ShowcaseColorsContext';

/**
 * TODO: Write JSDocs.
 */
function useShowcaseColorsContext() {
  const context = useContext<ShowcaseContextValues | [[], Record<string, never>]>(
    ShowcaseColorsContext,
  );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context === undefined) {
    throw new Error('useShowcaseColorsContext must be used within a ShowcaseColorsContextProvider');
  }

  return context;
}

export default useShowcaseColorsContext;
