import packageJson from '../package.json';
import type { Config } from './config.types';

const { NODE_ENV: env = 'development', PORT: port = 8080 } = process.env;

const defaultConfig = {
  env,
  hot: false,
  port,
  deploy: {
    Aliases: '',
    CertificateArn: '',
    Domain: '',
    HostedZoneId: 'Z2FDTNDATAQYW2',
    SubDomain: '',
    WebAclArn: '',
  },
  sentry: {
    dsn: 'https://78a65ac6687476e82fdef107efadf448@o292297.ingest.us.sentry.io/4507258859487232',
    environment: env,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    release: `ls-website-themes-client@v${packageJson.version}`,
    denyUrls: ['trkcall.com', 'google-analytics.com'],
  },
} satisfies Partial<Config>;

export default defaultConfig;
