import type { ShowcaseTemplatesQuery } from '@gqlTypes';
import type {
  FormattedShowcaseTemplate,
  FormattedShowcaseTemplates,
} from './showcaseTemplates.types';

/**
 * TODO: Write JSDocs.
 *
 * @param data
 */
function formatShowcaseTemplates(data: ShowcaseTemplatesQuery): FormattedShowcaseTemplates {
  const formatted = data.showcaseTemplates.edges.reduce((agg, edge) => {
    const newAgg = agg;

    if (edge?.node) {
      const { id, count, name } = edge.node;
      newAgg.push({ id, count, name });
    }

    return newAgg;
  }, [] as FormattedShowcaseTemplate[]);

  return formatted.sort((a, b) => a.name.localeCompare(b.name));
}

export default formatShowcaseTemplates;
