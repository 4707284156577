import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.children
 */
function Filters({ className, children, ...rest }: FiltersProps) {
  return (
    <div className={twMerge('flex flex-col gap-3', className)} {...rest}>
      {children}
    </div>
  );
}

type FiltersProps = HTMLAttributes<HTMLDivElement>;

export default Filters;
export type { FiltersProps };
