import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @extends HTMLAttributes<HTMLDivElement>
 * @param props - Props for the react element.
 */
function Container(props: ContainerProps) {
  const { className, children, ...divProps } = props;
  return (
    <div className={twMerge('mx-auto w-full max-w-screen-2xl px-6', className)} {...divProps}>
      {children}
    </div>
  );
}

type ContainerProps = HTMLAttributes<HTMLDivElement>;

export default Container;
export type { ContainerProps };
