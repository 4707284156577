import Icon from '@atoms/Icon';
import Pill from '@atoms/Pill';
import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';
import useShowcaseColorsContext from '../../../../../routes/Showcases/contexts/ShowcaseColorsContext';
import type { FormattedShowcaseColors } from '../../../../../routes/Showcases/data/showcaseColors';

/**
 * TODO: Write JSDocs.
 *
 * @param color
 * @param showcaseColors
 */
function getColorHexForPill(color: string, showcaseColors: FormattedShowcaseColors) {
  const showcaseColor = showcaseColors.find(
    ({ name }) => name.toLowerCase() === color.toLowerCase(),
  );
  return showcaseColor?.hex || '';
}

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.as
 * @param props.className
 * @param props.label
 * @param props.image
 * @param props.imageAlt
 * @param props.pills
 * @param props.href
 */
function DataCard({
  as = 'div',
  href,
  className,
  label,
  image,
  imageAlt,
  pills,
  ...rest
}: DataCardProps) {
  const [colors] = useShowcaseColorsContext();
  const Component = as !== 'a' ? as : 'div';

  const ImageNode = (
    <img
      className="h-80 w-full overflow-hidden rounded-t-card object-cover object-top"
      alt={imageAlt}
      src={image}
    />
  );

  const getColorPillBg = (color: string) => getColorHexForPill(color, colors);

  return (
    <Component
      className={twMerge(
        'flex w-full flex-col overflow-hidden rounded-card bg-white shadow-md transition-shadow',
        'hover:shadow-2xl',
        className,
      )}
      {...rest}
    >
      {href ?
        <a href={href} aria-label="Open website in new tab" target="_blank" rel="noreferrer">
          {ImageNode}
        </a>
      : ImageNode}

      <div className={twJoin('flex flex-1 flex-col justify-between px-6 pb-8 pt-6', 'sm:px-8')}>
        <div className="flex justify-between gap-2">
          <p className="text-bold hyphens-auto break-words pb-2.5 text-left text-h5">{label}</p>
          {as === 'a' && (
            <a
              href={href}
              aria-label="Open website in new tab"
              target="_blank"
              rel="noreferrer nofollow"
              className="pt-1"
            >
              <Icon name="link" />
            </a>
          )}
        </div>

        {pills?.length && (
          <div className="flex flex-wrap gap-1">
            {pills.map((pill) => (
              <Pill key={`${pill.id}${pill.label}`}>
                {pill.id === 'color' && (
                  <div
                    className="mr-1 h-2.5 w-2.5 rounded-full"
                    style={{ background: getColorPillBg(pill.label) }}
                  />
                )}
                {pill.label}
              </Pill>
            ))}
          </div>
        )}
      </div>
    </Component>
  );
}

type DataCardProps = Omit<
  HTMLAttributes<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>,
  'children'
> & {
  image: string;
  imageAlt: string;
  label: string;
  pills: { id: string | number; label: string }[] | null;
  as?: 'button' | 'a' | 'div' | undefined;
  href?: string;
};

export default DataCard;
export type { DataCardProps };
