import Icon from '@atoms/Icon';
import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';
import { twJoin } from 'tailwind-merge';
import useAccordionTitle from '../contexts/AccordionTitleContext';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.children
 * @param props.className
 */
function AccordionTitle({ children, className, ...rest }: AccordionTitleProps) {
  const { handleClick, open } = useAccordionTitle();
  return (
    <header
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleClick}
      className={twMerge('flex items-center justify-between p-5', className)}
      {...rest}
    >
      <p className="text-lg">{children}</p>
      <Icon name="chevron-down" className={twJoin('text-base', open && 'rotate-180')} />
    </header>
  );
}

type AccordionTitleProps = HTMLAttributes<HTMLElement>;

export default AccordionTitle;
export type { AccordionTitleProps };
