import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import NotFound from '../../../routes/NotFound';

/**
 * TODO: Write JSDocs.
 */
function SentryRouteErrorFallback() {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  return <NotFound />;
}

export default SentryRouteErrorFallback;
