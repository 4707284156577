import { createContext, useContext } from 'react';

const AccordionTitleContext = createContext<{
  handleClick: () => void;
  open: boolean;
} | null>(null);

const useAccordionTitle = () => {
  const accordionTitleData = useContext(AccordionTitleContext);
  if (accordionTitleData === null) {
    throw new Error('AccordionTitle must have an Accordion parent');
  }
  return accordionTitleData;
};

export default useAccordionTitle;
export { AccordionTitleContext };
