import { extendTailwindMerge } from 'tailwind-merge';

// TODO: Add JSDocs
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      'text-color': [{ text: ['text'] }],
    },
  },
});

export default twMerge;
