import twMerge from '@lib/twMerge';
import type { HTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 */
function LoadingCard({ className, ...divProps }: LoadingCardProps) {
  return (
    <div
      className={twMerge(
        'flex h-[430px] w-full flex-col overflow-hidden rounded-card bg-white shadow-md',
        className,
      )}
      {...divProps}
    >
      <div className="flex-1 bg-gray-100" />
      <div className="flex flex-col gap-1.5 bg-white p-8">
        <div className="flex justify-between">
          <div className="mr-4 h-8 w-full max-w-[200px] rounded-md bg-gray-100" />
          <div className="h-8 w-6 rounded-md bg-gray-100" />
        </div>
        <div className="h-8 w-full max-w-[calc(100%-40px)] bg-gray-100" />
      </div>
    </div>
  );
}

type LoadingCardProps = HTMLAttributes<HTMLDivElement>;

export default LoadingCard;
export type { LoadingCardProps };
