import twMerge from '@lib/twMerge';
import { type HTMLAttributes } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from './components/Tab/Tab';

/**
 * TODO: Write JSDocs.
 */
function useGetActiveTab() {
  const { pathname } = useLocation();
  if (pathname === '/portfolio') {
    return 'showcases';
  }
  return 'themes';
}

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 */
function NavigationTabs({ className, ...rest }: NavigationTabsProps) {
  const activeTab = useGetActiveTab();
  return (
    <div
      className={twMerge(
        'flex w-min justify-center gap-4 rounded-full bg-white p-3 shadow-md',
        className,
      )}
      {...rest}
    >
      <Tab to="/" isActive={activeTab === 'themes'}>
        Themes
      </Tab>
      <Tab to="/portfolio" isActive={activeTab === 'showcases'}>
        Portfolio
      </Tab>
    </div>
  );
}

type NavigationTabsProps = HTMLAttributes<HTMLDivElement>;

export default NavigationTabs;
export type { NavigationTabsProps };
