import { FilteredCards, Filters, Cards } from '@templates/FilteredCards';
import type { CardsProps } from '@templates/FilteredCards/Cards';
import { useEffect, useMemo, useState } from 'react';
import useGetFiltersFromParams from '../../hooks/useGetFiltersFromParams';
import useTemplatesData from './data/templates';
import TemplateFilters from './sections/TemplateFilters';

/**
 * TODO: Write JSDocs.
 */
function TemplatesPage() {
  const [templatesData, { refetch: refetchTemplates, loading }, rawData] = useTemplatesData();
  const { tags: tagsParam } = useGetFiltersFromParams<{ tags: string[] }>(['tags']);
  const [tags, setTags] = useState<string[]>(tagsParam || []);

  useEffect(() => {
    refetchTemplates({ tags });
  }, [tags, refetchTemplates]);

  const cardsData: CardsProps['data'] = useMemo(
    () =>
      templatesData.map((template) => ({
        ...template,
        imageAlt: `Screenshot of ${template.label} theme`,
        as: 'button',
      })),
    [templatesData],
  );

  return (
    <FilteredCards>
      <Filters>
        <TemplateFilters setTags={setTags} tags={tags} />
      </Filters>
      <Cards
        data={cardsData}
        loading={loading}
        dialog
        totalCount={rawData?.templates.totalCount || 0}
      />
    </FilteredCards>
  );
}

export default TemplatesPage;
