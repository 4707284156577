import { createContext, useContext } from 'react';

const AccordionContentContext = createContext<{ open: boolean } | null>(null);

const useAccordionContent = () => {
  const accordionContentData = useContext(AccordionContentContext);
  if (!accordionContentData) {
    throw new Error('AccordionContent must have an Accordion parent');
  }
  return accordionContentData;
};

export default useAccordionContent;
export { AccordionContentContext };
