import config from '@config';

const { loggerLevel } = config;

/**
 * A Logger class that works in both the browser and Node.js environments,
 * providing a consistent interface for logging messages at different log levels.
 */
class Logger {
  private static instance: Logger | null = null;

  private readonly currentLogLevel: number;

  private readonly logLevels: Record<LogLevel, number> = {
    error: 0,
    warn: 1,
    info: 2,
    debug: 3,
  };

  /**
   * Creates a new Logger instance.
   *
   * @param logLevel - The log level for the logger instance (error, info, debug, warn).
   */
  constructor(logLevel: LogLevel = 'info') {
    this.currentLogLevel = this.logLevels[logLevel];
  }

  /**
   * Get the Singleton instance of the Logger class.
   *
   * @returns The Singleton instance of the Logger class.
   */
  public static getInstance(): Logger {
    if (this.instance === null) {
      this.instance = new Logger(loggerLevel);
    }
    return this.instance;
  }

  /**
   * Logs a debug message.
   *
   * @param args - The message parts to be logged.
   */
  public debug(...args: unknown[]): void {
    this.log('debug', ...args);
  }

  /**
   * Logs an error message.
   *
   * @param args - The message parts to be logged.
   */
  public error(...args: unknown[]): void {
    this.log('error', ...args);

    // If the args contain an error with a stack, then log the stack.
    args.some((arg: unknown) => {
      const shouldLogStack = arg && typeof arg === 'object' && 'stack' in arg;
      if (shouldLogStack) {
        this.log('error', arg.stack);
      }
      return shouldLogStack;
    });
  }

  /**
   * Logs an informational message.
   *
   * @param args - The message parts to be logged.
   */
  public info(...args: unknown[]): void {
    this.log('info', ...args);
  }

  /**
   * Logs a warn message.
   *
   * @param args - The message parts to be logged.
   */
  public warn(...args: unknown[]): void {
    this.log('warn', ...args);
  }

  /**
   * Logs a message at the specified log level if it is at or below the current log level.
   *
   * @param level - The log level of the message (error, info, debug, warn).
   * @param args - The message parts to be logged.
   */
  private async log(level: LogLevel, ...args: unknown[]): Promise<void> {
    if (this.logLevels[level] > this.currentLogLevel) return;

    const environment =
      typeof window !== 'undefined' && typeof window.document !== 'undefined' ? 'browser' : 'node';

    if (environment === 'browser') {
      // eslint-disable-next-line no-console
      const logMethod = console[level];
      if (typeof logMethod === 'function') {
        const message = `[${level.toUpperCase()}]}`;
        logMethod.call(console, message, args);
      }
    } else {
      // const message = args.join(' ');
      // const serverLogger = (await import('../lib/winston')).default;
      // // Use the server logger module for Node.js environment
      // serverLogger[level](message);
    }
  }
}

type LogLevel = typeof loggerLevel;

export default Logger.getInstance();
