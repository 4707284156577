import twMerge from '@lib/twMerge';
import { type ButtonHTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.children
 * @param props.disabled
 * @param props.isCurrentPage
 */
function PaginationButton({
  className,
  children,
  disabled,
  isCurrentPage,
  ...buttonProps
}: PaginationButtonProps) {
  return (
    <button
      type="button"
      className={twMerge(
        'flex h-8 items-center justify-center rounded-md text-base',
        'disabled:cursor-default disabled:text-gray-400',
        'hover:bg-gray-200',
        !!isCurrentPage && 'bg-gray-200',
        className,
      )}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

type PaginationButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isCurrentPage?: boolean;
};

export default PaginationButton;
export type { PaginationButtonProps };
