import twMerge from '@lib/twMerge';
import type { ButtonHTMLAttributes, MouseEvent } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

/**
 * TODO: Write JSDocs.
 *
 * @param e
 */
function handleMouseMove(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
  const rect = e.currentTarget.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  const xPercent = x / e.currentTarget.offsetWidth;
  const yPercent = y / e.currentTarget.offsetHeight;
  e.currentTarget.style.setProperty('--mouse-x', String(xPercent * 100));
  e.currentTarget.style.setProperty('--mouse-y', String(yPercent * 100));
}

/**
 * TODO: Write JSDocs.
 *
 * @extends HTMLAttributes<HTMLButtonElement>
 * @param props - The component props.
 * @param props.asAnchor - Optional. Render as an '<a>' anchor element. Defaults to false.
 * @param props.children
 * @param props.external
 * @param props.href
 * @param props.className
 */
function Button({ asAnchor = false, external = false, children, className, ...rest }: ButtonProps) {
  if (asAnchor) {
    return (
      <Link
        onMouseMove={handleMouseMove as LinkProps['onMouseMove']}
        className={twMerge(
          'relative z-10 flex h-11 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-primary px-5 py-2.5 text-base text-white',
          'after:absolute after:inset-0 after:-z-20 after:bg-primary after:content-[""]',
          'before:absolute before:inset-0 before:-z-10 before:bg-[radial-gradient(circle_at_center_center,#97AEFF_0%,#285AFF_100%)] before:bg-[length:200%_200%] before:bg-[calc((100_-_var(--mouse-x,_0))_*_1%)_calc((100_-_var(--mouse-y,_0))_*_1%)] before:bg-no-repeat before:opacity-0 before:transition-opacity before:content-[""]',
          'hover:before:opacity-100',
          className,
        )}
        {...(external ?
          { target: '_blank', rel: 'noreferrer nofollow', reloadDocument: true }
        : {})}
        {...(rest as LinkProps)}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      onMouseMove={handleMouseMove}
      className={twMerge(
        'relative z-10 flex h-11 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-primary px-5 py-2.5 text-base text-white',
        'after:absolute after:inset-0 after:-z-20 after:bg-primary after:content-[""]',
        'before:absolute before:inset-0 before:-z-10 before:bg-[radial-gradient(circle_at_center_center,#97AEFF_0%,#285AFF_100%)] before:bg-[length:200%_200%] before:bg-[calc((100_-_var(--mouse-x,_0))_*_1%)_calc((100_-_var(--mouse-y,_0))_*_1%)] before:bg-no-repeat before:opacity-0 before:transition-opacity before:content-[""]',
        'hover:before:opacity-100',
        className,
      )}
      {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
}

type ButtonElementProps = {
  asAnchor?: false | undefined;
  external?: undefined;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorElementProps = {
  asAnchor: true;
  external?: boolean;
} & LinkProps;

type ButtonProps = ButtonElementProps | AnchorElementProps;

export default Button;
export type { ButtonProps };
