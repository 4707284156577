import { FilteredCards, Filters, Cards } from '@templates/FilteredCards';
import type { CardsProps } from '@templates/FilteredCards/Cards';
import { useEffect, useMemo, useState } from 'react';
import useGetFiltersFromParams from '../../hooks/useGetFiltersFromParams';
import { ShowcaseColorsContextProvider } from './contexts/ShowcaseColorsContext';
import useShowcasesData from './data/showcases';
import ShowcaseFilters from './sections/ShowcaseFilters';
import type { ShowcaseFiltersState } from './Showcases.types';

/**
 * TODO: Write JSDocs.
 */
function ShowcasesPage() {
  const [showcasesData, { refetch: refetchShowcases, loading }, rawData] = useShowcasesData();
  const filterValues = useGetFiltersFromParams<{
    colors: string[];
    features: string[];
    industries: string[];
    templates: string[];
  }>(['colors', 'features', 'templates', 'industries']);
  const [filters, setFilters] = useState<ShowcaseFiltersState>({
    colors: [],
    features: [],
    industries: [],
    templates: [],
    ...filterValues,
  });

  useEffect(() => {
    refetchShowcases(filters);
  }, [filters, refetchShowcases]);

  const cardsData: CardsProps['data'] = useMemo(
    () =>
      showcasesData.map((showcase) => ({
        ...showcase,
        imageAlt: `Screenshot of ${showcase.label} website using ${showcase.pills[2]} theme`,
        as: 'a',
        href: showcase.url,
        target: '__blank',
      })),
    [showcasesData],
  );

  return (
    <ShowcaseColorsContextProvider>
      <FilteredCards>
        <Filters>
          <ShowcaseFilters filters={filters} setFilters={setFilters} />
        </Filters>
        <Cards data={cardsData} loading={loading} totalCount={rawData?.showcases.totalCount || 0} />
      </FilteredCards>
    </ShowcaseColorsContextProvider>
  );
}

export default ShowcasesPage;
