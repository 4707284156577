import twMerge from '@lib/twMerge';
import createPseudoRandomWidth from '@utils/createPseudoRandomWidth';
import type { HTMLAttributes } from 'react';

/**
 * TODO: Write JSDocs.
 *
 * @param props
 * @param props.className
 * @param props.checkboxProps
 * @param props.count
 */
function CheckboxLoadingList({
  className,
  checkboxProps,
  count,
  ...divProps
}: CheckboxLoadingListProps) {
  const { className: checkboxClassName, ...restCheckboxProps } = checkboxProps || {};
  return (
    <div className={twMerge('flex flex-col gap-1', className)} {...divProps}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={twMerge('h-6 bg-gray-100', checkboxClassName)}
          style={{ width: createPseudoRandomWidth(index) }}
          {...restCheckboxProps}
        />
      ))}
    </div>
  );
}

type CheckboxLoadingListProps = HTMLAttributes<HTMLDivElement> & {
  count: number;
  checkboxProps?: HTMLAttributes<HTMLDivElement>;
};

export default CheckboxLoadingList;
export type { CheckboxLoadingListProps };
